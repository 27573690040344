*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Axiforma', sans-serif;
}
@font-face {
  font-family: 'Axiforma';
  src: url('../src/Componenets/Assets/Font/Axiforma-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Axiforma';
  src: url('../src/Componenets/Assets/Font/Axiforma-Medium.ttf') format('truetype');
  font-weight: 500; 
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: url('../src/Componenets/Assets/Font/Axiforma-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
.title{
    text-align: center;
}
.title h2{
    color: #35543E;
    font-size: 48px;
    font-weight: 700;
    letter-spacing: 1px;
}

.delete-container {
  display: grid;
  padding: 2rem 1rem;
  place-items: center;
  min-height: 100vh;
}

.delete-container main {
  width: clamp(20rem, 90vw, 40rem);
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.delete-container main img {
  width: clamp(4rem, 20vw, 6rem);

}

.delete-container main p span {
  color: red;
}

.delete-container main p {
  font-size: clamp(.85rem, 2vw, 1rem);
  opacity: 0.6;
}

.delete-container main form {
  display: grid;
  gap: 1rem;
}

.inp-container {
  display: grid;
  text-align: left;
  gap: 0.5rem;
}

.inp-container label {
  font-size: clamp(.8rem, 2vw, 0.9rem);
}

.error {
  color: red;
  opacity: 0.8;
}

.inp-container input.error {
  outline: 2px solid red;
  border: transparent;
}

.inp-container button {
  border: 1px solid rgba(0, 0, 0, 0.25);
  background-color: #ed4337;
  color: #fff;
}

.clickable {
  cursor: pointer;
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}

.clickable:active {
  opacity: 0.5;
  transform: scale(0.95);
}

.inp-container input, .inp-container button {
  outline: 2px solid transparent;
  border-radius: 0.5rem;
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0.25);
  padding: 0.75rem 1rem;
}


.passwordEye {
  width: 100%;
  position: relative;
  background-color: red;

  img {
      position: absolute;
      top: -2.15rem;
      right: 1.5rem;
      max-width: 1.25rem;
      cursor: pointer;
      opacity: 0.3;
    }

    img:hover {
      opacity: 0.5;
    }

    img:active {
      opacity: 0.3;
      transform: scale(.9);
    }

    .inactive {
      opacity: 0;
      pointer-events: none;
    }
}