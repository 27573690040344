* {
  transition: all 0.15s ease;
}
.row {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  padding: 0 15px;
  gap: 2rem;
}

.right-div {
  display: grid;
  background: linear-gradient(#51bb8e, #686b8c);
  flex: 1;
  padding: 0;
  border-radius: 12px;
  place-items: end center;
  background-color: red;
  overflow: hidden;
  position: relative;
  min-height: 30rem;
}

.right-div img {
  width: 25rem;
  margin-bottom: -1rem;
}

.right-div .btn {
  position: absolute;
  right: 2rem;
  top: 2rem;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
}

.left-div {
  padding-block: 1.5rem;
  padding-left: 5%;
  flex-basis: 50%;
  background: #fafafa;
  border-radius: 12px;
}
.img-div {
  padding-left: 15px;
}
.left-div h1 {
  margin-top: 120px;
  color: #51b48c;
  letter-spacing: 1px;
  line-height: 70px;
  font-weight: 700;
  font-size: 60px;
}
.left-div p {
  margin: 50px 0 30px;
  color: #35543e;
  font-size: 18px;
}
.btn {
  padding: 1rem 2rem;
  background: linear-gradient(90deg, #51bb8e, #686b8c);
  cursor: pointer;
  border: none;
  border-radius: 0.8rem;
  color: white;
  font-size: 1rem;
  text-align: center;
}

.btn-img {
  cursor: pointer;
}

.btn:active, .btn-img:active {
  transform: scale(0.9);
}

.btn2 {
  display: none;
  margin-right: 5%;
}
.store-btn {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
  text-align: left;
}
.btn-contain {
  display: flex;
  align-items: center;
  background-color: #1a1a1a;
  text-align: center;
  padding: 8px 30px 8px 100px;
  width: 50%;
  border-radius: 20px;
  color: white;
  cursor: pointer;
  border: none;
}
.btn-contain img {
  margin-right: 6px;
  width: 24px;
}
.btn-contain div small {
  display: block;
  font-size: 8px;
}
.btn-contain div small:last-child {
  margin-top: -5px;
  font-size: 16px;
  font-weight: 500;
}


@media only screen and (max-width: 1440px) {

  .store-btn img {
    width: 280px;
  }
}

@media only screen and (max-width: 1400px) {
  .store-btn img {
    width: 250px;
  }
}
@media only screen and (max-width: 1250px) {
  .row {
    flex-wrap: wrap;
  }
  .store-btn {
    justify-content: center;
  }
  .store-btn img {
    width: 300px;
  }
  .btn {
    display: none;
  }

  .btn2 {
    position: absolute;
    display: block;
    height: fit-content;
    top: 4rem;
    right: 1rem;
  }
  .row {
    text-align: center;
  }

  .right-div img {
    width: 18rem;
    margin-bottom: -1rem;
  }

  .left-div {
    flex-basis: 100%;
    text-align: left;
  }
  .left-div h1 {
    margin-top: 50px;
    font-size: 55px;
    line-height: 65px;
  }
  .left-div p {
    font-size: 20px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 990px) {
  .left-div h1 {
    font-size: 47px;
    line-height: 57px;
    margin-top: 60px;
  }
  .left-div p {
    font-size: 20px;
    line-height: 28px;
  }
  
  .right-div img {
    width: 18rem;
    margin-bottom: -1rem;
  }

  .right-div {
    gap: 1rem;
  }

  .left-div {
    margin-bottom: 80px;
  }
  .store-btn {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 770px) {
  .right-div {
    margin-top: -5rem;
  }
  
  .right-div img {
    width: 18rem;
    margin-bottom: -1rem;
  }
  .left-div h1 {
    margin-top: 30px;
    font-size: 43px;
    line-height: 50px;
  }
}
@media only screen and (max-width: 550px) {
  .right-div {
    height: 510px;
  }
  
  .right-div img {
    width: 18rem;
    margin-bottom: -1rem;
  }
}
