.work {
  padding: 50px 0 60px;
  width: 90%;
  margin: auto;
}
.work-row {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  gap: 30px;
  justify-content: center;
}
.card {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: linear-gradient(#50c591, #39415f);
  border-radius: 18px;
  color: white;
  position: relative;
  height: 380px;
  padding: 65px 10px 10px 30px;
  margin-bottom: 30px;
}
.card h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}
.store-btn2{
  margin-top: -20px;
  justify-content: center;
}
.footer {
  background: #51b48c;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  padding: 20px;
  margin-top: 100px;
}

@media only screen and (max-width: 1216px) {
  .work-row {
    grid-template-columns: 65%;
  }
  .card h3 {
    font-size: 25px;
  }
  .card p {
    font-size: 18px;
  }
}
@media only screen and (max-width: 850px) {
  .work {
    width: 70%;
    margin: auto;
    justify-content: center;
  }
  .work-row {
    grid-template-columns: 1fr;
  }

  .work-row .card {
    min-width: 400px;
  }
}
@media only screen and (max-width: 790px) {
  .card p {
    margin-top: 55px;
  }
  .work {
    width: 85%;
    margin: auto;
    justify-content: center;
  }
  .card h3 {
    margin-bottom: -50px;
  }
  .work-row .card {
    min-width: 400;
  }
}
